import axios from 'axios';

export const CityStore = {
    namespaced: true,
    state: {
        loading: false,
        cityData: null,
        yesterdayWeather: null, 
        allCities: [],
        heatingDegreeDays: null,
        airQuality: null,  // New state for air quality data
    },
    getters: {
        loading: (state) => state.loading,
        cityData: state => state.cityData,
        yesterdayWeather: state => state.yesterdayWeather,
        heatingDegreeDays: state => state.heatingDegreeDays,
        allCities: state => state.allCities,
        airQuality: state => state.airQuality,  // New getter for air quality data
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setAllCities: (state, payload) => {
            state.allCities = payload;
        },
        setCityData: (state, payload) => {
            state.cityData = payload;
        },
        setYesterdayWeather: (state, payload) => {
            state.yesterdayWeather = payload;
        },
        setHeatingDegreeDays: (state, payload) => {
            state.heatingDegreeDays = payload;
        },
        setAirQuality: (state, payload) => {  // New mutation to set air quality data
            state.airQuality = payload;
        },
    },
    actions: {
        getAllCities({ commit }) {
            commit('startLoading');
            axios.get('city').then(({ data }) => {
                commit('setAllCities', data);
                commit('stopLoading');
            });
        },
        getCityById({ commit }, cityId) {
            commit('startLoading');
            axios.get(`city/${cityId}`).then(({ data }) => {
                commit('setCityData', data);
                console.log(data)
                commit('stopLoading');
            });
        },
        getYesterdayWeather({ commit }, cityId) {
            commit('startLoading');
            axios.get(`city/${cityId}/yesterday-weather`).then(({ data }) => {
                commit('setYesterdayWeather', data);
                console.log(data);
                commit('stopLoading');
            }).catch(error => {
                console.error('Error fetching yesterday\'s weather:', error);
                commit('stopLoading');
            });
        },
        getHeatingDegreeDays({ commit }, cityId) {
            commit('startLoading');
            axios.get(`city/${cityId}/HDD`).then(({ data }) => {
                commit('setHeatingDegreeDays', data.HTML);
                console.log(data.HTML);
                commit('stopLoading');
            }).catch(error => {
                console.error('Error fetching heating degree days:', error);
                commit('stopLoading');
            });
        },
        getYesterdayAirQuality({ commit }, cityId) {  // New action to fetch yesterday's air quality
            commit('startLoading');
            axios.get(`city/${cityId}/yesterday-air-quality`).then(({ data }) => {
                commit('setAirQuality', data);
                console.log(data);
                commit('stopLoading');
            }).catch(error => {
                console.error('Error fetching yesterday\'s air quality:', error);
                commit('stopLoading');
            });
        }
    }
};
